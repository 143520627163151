.root {
  display: 'flex';
  flexDirection: 'column';
  /* // backgroundColor:colors.blanco, */
  height: '100vh';
  width: '100%';
}

.menu {
  display: 'flex';
  zIndex: 1;
}

.rootMap {
  display: 'flex';
  flexDirection: 'column';
}

.map {
  display: 'flex';
  height: '100%';
  width: '100%';
  height: '100vh';
  position: 'relative';
}

.paper {
  position: 'absolute';
  width: 400;
  textAlign: 'center';
}